import { graphql } from "gatsby";
import ContactUs from "../templates/contactUs";

const ContactUsPage = ({
	data: {
		craft: { entry },
	},
}) => <ContactUs entry={entry} />;

export const pageQuery = graphql`
	query ContactUsPage {
		craft {
			entry(slug: "contact-us") {
				... on CraftCMS_contactUs_contactUs_Entry {
					heroTitle
					heroText
					contactUsChatBlockTitle
					contactUsChatBlockText
					contactUsDetailsBlockTitle
					contactUsDetailsBlockText
					faqsTitle
					faqsItems {
						... on CraftCMS_faqsItems_faqsItem_BlockType {
							faqsItemTitle
							faqsItemText
						}
					}
					metaTitle
					metaDescription
					metaShareImage {
						filename
						url
					}
				}
			}
		}
	}
`;

export default ContactUsPage;
